import React, { useState } from "react";

import Axios from "axios";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";

import { PDPContext } from "../../App";
import { colors } from "../../generalStyle";
import { getData } from "../../utils/getData";
import { CreateButton } from "../CreateButton";
import { EditButton } from "../EditButton";
import { AdminButtons, SectionContainer, Tooltip } from "../Styles";
import reviewIcon from "../images/icons8-chat-100.webp";
import {
  InfoReviewer,
  InsideSlideWrapper,
  QuoteReviewSection,
  StyledReviewIcon,
  StyledReviewSection,
  StyledRoundedImage,
  StyledTitleContainer,
  TitleReviewSection,
} from "./ReviewSection.style.style";
import "./ReviewSection.styles.css";

export const ReviewSection = () => {
  const contextLocal = React.useContext(PDPContext);
  // const [contentText, setContentTexts] = useState({
  //   title: "Ce spun cei care au vorbit deja cu profa?",
  //   content: [
  //     {
  //       text: "Orice om are o menire, iar menirea Profei de povești e sa îți liniștească sufletul si mintea. Când vorbesti cu ea simti o alinare angelica, orice problema capătă o rezolvare, iar lumea nu mai pare asa gri. Ea mi a redat încrederea in mine, mi-a aratat ca exista mereu o cale pentru a îndepărta obstacolele din viata mea si mi-a aratat ce prietena extraordinara imi este atunci cand am cea mai mare nevoie.",
  //       name: "Diana Andreea",
  //       age: "21 ani",
  //       avatar: "",
  //     },      {
  //       text: "Profa de povești este foarte intelegatoare, dedicată si inteligenta și ma bucur ca am întâlnit-o si am fost destul de norocos încât sa dorească sa ma asculte si să mă învețe cum să trec mai usor printr-o perioadă încărcată a vieții mele.",
  //       name: "Mihai Popa",
  //       age: "23 ani",
  //       avatar: "",
  //     },   {
  //       text: "Întâlnirea cu Profa de povești este total diferită de alte experiențe pe care le-am avut! Am învățat diferite metode de autoevaluare a propriilor resurse, având astfel posibilitatea să mă descopăr și să redescopăr din perspective total diferite de cele pe care le cunoșteam. Recomand intalnirile inspiraționale cu Profa de povești căreia îi mulțumesc pe această cale pentru o vacanță de vară îmbogățită cu aceste experiențe de dezvoltare personală și profesională.",
  //       name: "LNT",
  //       age: "54 ani",
  //       avatar: "",
  //     }, {
  //       text: "Profa de povești m-a inspirat și ghidat să devin omul care sunt acum! Mulțumesc, dragă Profă de povești!",
  //       name: "Bianca",
  //       age: "25 ani",
  //       avatar: "",
  //     },
  //   ],
  //   video: "",
  // });
  const [contentText, setContentTexts] = useState({
    title: "",
    content: [
      {
        text: "",
        name: "",
        age: "",
        avatar: "",
      },
    ],
    video: "",
  });
  React.useEffect(() => {
    Axios.get("/api/get-content", {
      params: {
        section: "testimonials",
      },
    }).then((res) => {
      if (res.data.content[0].data !== "{}") {
        const rawData = JSON.parse(res.data.content[0].data);
        setContentTexts(rawData);
      }
    });
  }, []);
  // React.useEffect(() => {
  //   const textData = getData(contextLocal?.allCategories, "ReviewSection");
  //   setContentTexts(textData);
  // }, [contextLocal]);

  const ReviewsSection = contentText.content?.map((review, index) => {
    return (
      <SwiperSlide key={index}>
        <InsideSlideWrapper>
          {localStorage?.user &&
            localStorage?.user !== "null" &&
            window.screen.width >= 1200 && (
              <button
                className={"delete-button"}
                onClick={() => {
                  contextLocal?.editFunction(
                    { data: contentText, index: index },
                    "delete"
                  );
                }}
              >
                <Tooltip>Delete this review</Tooltip>X
              </button>
            )}
          <StyledRoundedImage src={review.avatar} alt="Avatar" />
          <QuoteReviewSection>{review.text}</QuoteReviewSection>
          <InfoReviewer>
            {review.name} - {review.age}
          </InfoReviewer>
        </InsideSlideWrapper>
      </SwiperSlide>
    );
  });

  return (
    <StyledReviewSection>
      <SectionContainer>
        <AdminButtons>
          <CreateButton
            createFunction={contextLocal?.editFunction}
            sectionText={contentText}
            createType="createReview"
          />
          <EditButton
            editFunction={contextLocal?.editFunction}
            sectionText={contentText}
            setSectionName={contextLocal.setSectionName}
            section={"testimonials"}
          />
        </AdminButtons>
        <StyledTitleContainer>
          <StyledReviewIcon src={reviewIcon} alt="reviewIcon" />
          <TitleReviewSection color={colors.primary.base}>
            {contentText.title}
          </TitleReviewSection>
        </StyledTitleContainer>
        <Swiper
          speed={1000}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
        >
          {ReviewsSection}
        </Swiper>
      </SectionContainer>
    </StyledReviewSection>
  );
};

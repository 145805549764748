import React, { useState } from "react";

import Axios from "axios";
import { log } from "util";

import { PDPContext } from "../../App";
import { getData } from "../../utils/getData";
import { EditButton } from "../EditButton";
import {
  AdminButtons,
  MainContentWrapper,
  MainPagePhoto,
  MainPageText,
  MainTitle,
  PageContainer,
} from "../Styles";
import bookshelf from "../images/bookshelf_compressed.webp";

export const MainPage = () => {
  const contextLocal = React.useContext(PDPContext);
  // const [sectionText, setSectionTexts] = useState({
  //   title: "Cadoul tau e mai frumos cand spune o poveste",
  //   subTitle:
  //     "„Să-ți spun o poveste!”, și dintr-o dată, totul în jur pare că nu mai are importanță, zgomotele se estompează, imaginile din fundal devin neclare, rămâne doar curiozitatea pentru întâmplările pe care urmează să le aflu.",
  // });
  const [sectionText, setSectionTexts] = useState({
    title: "",
    subTitle: "",
  });

  React.useEffect(() => {
    Axios.get("/api/get-content", {
      params: {
        section: "home",
      },
    }).then((res) => {
      if (res.data.content[0].data !== "{}") {
        const rawData = JSON.parse(res.data.content[0].data);
        setSectionTexts(rawData);
      }
    });
  }, []);

  return (
    <>
      <MainPagePhoto backgroundImage={bookshelf}>
        <AdminButtons position={"position:absolute; margin-top:50px;"}>
          <EditButton
            editFunction={contextLocal?.editFunction}
            sectionText={sectionText}
            setSectionName={contextLocal.setSectionName}
            section={"home"}
          />
        </AdminButtons>
        <MainContentWrapper flexDirection="row">
          <PageContainer>
            {<MainTitle>{sectionText.title}</MainTitle>}
            {<MainPageText>{sectionText.subTitle}</MainPageText>}
          </PageContainer>
        </MainContentWrapper>
      </MainPagePhoto>
    </>
  );
};

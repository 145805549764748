import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import ScrollToTop from "./ScrollToTop";
import { UnknownRoute } from "./UnknownRoute";
import { Contact } from "./comp/Contact/Contact";
import { CourseInfo } from "./comp/CourseInfo/CouseInfo";
import { Courses, childrenCourses, teacherCourses } from "./comp/Courses";
import { CreateModal } from "./comp/CreateModal/CreateModal";
import { DeleteModal } from "./comp/DeleteModal";
import { EditModal } from "./comp/EditModal/EditModal";
import { EventInfo } from "./comp/Events/EventInfo";
import { Events, events } from "./comp/Events/Events";
import { Footer } from "./comp/Footer";
import { Home } from "./comp/Home";
import Loading from "./comp/Loading/Loading";
import Login from "./comp/Login/Login";
import { Navbar } from "./comp/Navbar";
import { StoryBoxPage } from "./comp/StoryBox/StoryBox";
import { StyledWrapper } from "./comp/Styles";
import { ToTopButton } from "./comp/ToTopButton";
import { WeekStorySection } from "./comp/WeekStorySection/WeekStorySection";
import { WorkInProgress } from "./comp/WorkInProgress";
import linkGenerate from "./generalFunction";
import useFetch from "./hooks/useFetch";
import { getData } from "./utils/getData";

interface PDPContextType {
  allCategories: any[];
  editFunction: (data: any, type: string, object?: object) => boolean;
  setSectionName?: (val: string) => void;
}

// Create the context with the default value
export const PDPContext = React.createContext<PDPContextType>({
  allCategories: [],
  editFunction: (data: any, type: string, object?: object) => false,
  setSectionName: undefined,
});

export interface Category {
  content: string[];
  img: string[];
  language: string;
  sectionName: string;
  subTitle: string;
  video: string;
  links: string[];
  contain: string[];
  title: string;
  _id: string;
  __v: number;
}
console.log = console.warn = console.error = () => {};

function App() {
  const topRef = React.useRef<null | HTMLDivElement>(null);
  const [isTopButton, setTopButton] = React.useState(false);
  const [allCategories, setAllCategories] = React.useState([]);
  const [navbarText, setNavbarTexts] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [modalObject, setModalObject] = useState({});
  const [sectionName, setSectionName] = useState("");
  const [editModal, setEditModal] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<{
    visibility: boolean;
    schema: string;
  }>({
    visibility: false,
    schema: "none",
  });

  const path = window.location.pathname.split("/")[1];
  // let langArr = ["ro", "en", "fr"];
  let langArr = ["ro"];
  if (langArr.includes(path)) {
    localStorage.setItem("locale", path);
  } else if (path) {
    localStorage.setItem("locale", "ro");
  } else {
    localStorage.setItem("locale", "ro");
    window.location.href = `/ro`;
  }

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [allLinkCoursesChildParents, setLinkCoursesChildParents] =
    useState(childrenCourses);
  const [allLinkCoursesTeacher, setLinkCoursesTeacher] =
    useState(teacherCourses);
  const [coursesIDs, setCoursesIDs] = useState([]);
  const [allEvents, setAllEvents] = useState(events);
  const { data, loading } = useFetch(
    `https://api.profadepovesti.ro/api/sections/sectionByLanguage?language=${
      localStorage?.locale || "ro"
    }`
  );
  const editFunction = (data: any, type: string, object?: object) => {
    switch (type) {
      case "edit": {
        setEditModal(true);
        break;
      }
      case "createCourse":
        setCreateModal({ visibility: true, schema: "course" });
        break;
      case "createEvent":
        setCreateModal({ visibility: true, schema: "event" });
        break;
      case "createReview":
        setCreateModal({ visibility: true, schema: "review" });
        break;
      case "delete":
        setDeleteModal(true);
        break;
    }
    setModalData(data);
    setModalObject({});
    if (object) {
      setModalObject(object);
    }
  };

  const eventsLinkRoutes = allEvents.map((link, key) => {
    return (
      <Route
        key={key}
        path={`/${localStorage?.locale}/evenimente/${linkGenerate(
          link.titleSection
        )}`}
        element={
          <EventInfo
            title={link?.titleSection}
            eventImage={link?.image}
            text={link?.text}
            date={link?.date}
            time={link?.time}
            website={link?.website}
            imageAlt={link?.alt}
          />
        }
      />
    );
  });

  const coursesLinkRoutesChildParents = allLinkCoursesChildParents.map(
    (link, key) => {
      return (
        <Route
          key={key}
          path={`/${localStorage?.locale}/cursuri/${linkGenerate(link.title)}`}
          element={
            <CourseInfo
              course={coursesIDs[0]}
              title={link.title}
              text={link.text}
              section={"course1"}
            />
          }
        />
      );
    }
  );
  const coursesLinkRoutesTeacher = allLinkCoursesTeacher.map((link, key) => {
    return (
      <Route
        key={key}
        path={`/${localStorage?.locale}/cursuri/${linkGenerate(link.title)}`}
        element={
          <CourseInfo
            course={coursesIDs[1]}
            title={link.title}
            text={link.text}
            section={"course2"}
          />
        }
      />
    );
  });
  const arrayNavbarLinks = [
    <StoryBoxPage />,
    <WeekStorySection separatePage={true} />,
    <Courses />,
    <Events />,
    <Contact />,
  ];
  const navData = [
    "Cutia cu povești",
    "Povestea săptămânii",
    "Cursuri",
    "Evenimente",
    "Vorbeste cu profa",
  ];
  const navbarLinks = navData.map((link, index) => {
    return (
      <Route
        key={index}
        path={`/${localStorage?.locale}/${linkGenerate(link)}`}
        element={arrayNavbarLinks[index]}
      />
    );
  });

  return (
    <StyledWrapper>
      {/*{data.length === 0 ? (*/}
      {/*  <Loading />*/}
      {/*) : (*/}
      <>
        {editModal && (
          <EditModal
            modalData={modalData}
            editModal={editModal}
            setEditModal={setEditModal}
            createModalSchema={createModal.schema}
            object={
              Object.keys(modalObject).length >= 1 ? modalObject : undefined
            }
            sectionName={sectionName}
          />
        )}
        {createModal.visibility && (
          <CreateModal
            data={modalData}
            createModalSchema={createModal.schema}
            setCreateModal={setCreateModal}
          />
        )}
        {deleteModal && (
          <DeleteModal
            modalData={modalData}
            setDeleteMOdalOpen={setDeleteModal}
          />
        )}
        <PDPContext.Provider
          value={{
            allCategories,
            //@ts-ignore
            editFunction,
            setSectionName,
          }}
        >
          <BrowserRouter>
            <div ref={topRef} />
            <Navbar allCategories={allCategories} setTopButton={setTopButton} />
            <ScrollToTop />
            <Routes>
              <Route path={`/${localStorage?.locale}`} element={<Home />} />
              {navbarLinks}
              <Route path="/blog" element={<WorkInProgress />} />
              <Route path="/login" element={<Login />} />
              {coursesLinkRoutesChildParents}
              {coursesLinkRoutesTeacher}
              {eventsLinkRoutes}

              <Route path="*" element={<UnknownRoute />} />
            </Routes>
            <ToTopButton topRef={topRef} isTopButton={isTopButton} />
            <Footer />
          </BrowserRouter>
        </PDPContext.Provider>
      </>
      {/*)}*/}
    </StyledWrapper>
  );
}

export default App;

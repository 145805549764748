import React, { useState } from "react";

import { PDPContext } from "../../App";
import { colors } from "../../generalStyle";
import { TextAlignment } from "../../utils/TextAlignment";
import { getData } from "../../utils/getData";
import { EditButton } from "../EditButton";
import {
  AdminButtons,
  BackgroundWrapper,
  LogoutImage,
  ParagraphItalicStyled,
  SectionContainer,
  TitleSection,
} from "../Styles";
import { YoutubeEmbed } from "../YoutubeEmbed/YoutubeEmbed";
import Background from "../images/WeekStorySection.webp";
import logout from "../images/logout-icon.webp";
import Axios from "axios";

interface weekStorySectionProps {
  separatePage?: boolean;
}

export const WeekStorySection = (props: weekStorySectionProps) => {
  const contextLocal = React.useContext(PDPContext);
  // const [contentText, setContentTexts] = useState({
  //   title: "Povestea Săptămânii",
  //   content: [
  //     "Nu-i așa că vă era dor de Profa de povești? Am venit să vă spun cum am văzut odată o gărgăriță. Au fost și încă sunt ele multe gărgărițe, dar aleg să spun azi povestea uneia care stătea pe o frunză cărnoasă de iarbă plină de picături de rouă. Gărgărița ieșise de sub frunza care o protejase și căuta cu antenele împrejur, deopotrivă nesigură și curioasă de ce avea să descopere. Nu știu dacă era tânără sau bătrână, nu știu de câtă vreme ședea pe frunza de iarbă. Oricum timpul gărgărițelor se măsoară cu alt ceas decât acela al oamenilor. Stătea pe frunză, alături de picătura de rouă, cu aripile întredeschise. O voce îi spunea gărgăriței că dacă își deschide aripile să zboare prea sus, elitrele ei tari o vor împiedica și va cădea. O altă voce îi șoptea că aripile ei vor fi protejate și astfel va putea ajunge în înaltul cerului. Ce să aleagă, se zbuciuma gărgărița? Gândul la libertatea pe care i-o oferea zborul o amețea. Și gândul la picajul în care putea intra o înfricoșa. Nu știu ce a hotărât gărgărița în final. Știu doar că zbuciumul ei a învățat-o că aripile îți dau libertate doar atunci când sunt larg deschise în zbor, altfel sunt doar o povară grea pe care o porți în spate. Nefericit.",
  //   ],
  //   video: "-WgvGqYRjuw?si=-UjFOkv9ObSaMy_N",
  // });
  const [contentText, setContentTexts] = useState({
    title: "",
    content: [""],
    video: "",
  });
  React.useEffect(() => {
    Axios.get("/api/get-content", {
      params: {
        section: "weeksstory",
      },
    }).then((res) => {
      if (res.data.content[0].data !== "{}") {
        const rawData = JSON.parse(res.data.content[0].data);
        setContentTexts(rawData);
      }
    });
  }, []);
  // React.useEffect(() => {
  //   const textData = getData(contextLocal?.allCategories, "WeekStorySection");
  //   setContentTexts(textData);
  // }, [contextLocal]);

  const ParagraphAboutSection = [
    "Nu-i așa că vă era dor de Profa de povești? Am venit să vă spun cum am văzut odată o gărgăriță. Au fost și încă sunt ele multe gărgărițe, dar aleg să spun azi povestea uneia care stătea pe o frunză cărnoasă de iarbă plină de picături de rouă. Gărgărița ieșise de sub frunza care o protejase și căuta cu antenele împrejur, deopotrivă nesigură și curioasă de ce avea să descopere. Nu știu dacă era tânără sau bătrână, nu știu de câtă vreme ședea pe frunza de iarbă. Oricum timpul gărgărițelor se măsoară cu alt ceas decât acela al oamenilor. Stătea pe frunză, alături de picătura de rouă, cu aripile întredeschise. O voce îi spunea gărgăriței că dacă își deschide aripile să zboare prea sus, elitrele ei tari o vor împiedica și va cădea. O altă voce îi șoptea că aripile ei vor fi protejate și astfel va putea ajunge în înaltul cerului. Ce să aleagă, se zbuciuma gărgărița? Gândul la libertatea pe care i-o oferea zborul o amețea. Și gândul la picajul în care putea intra o înfricoșa. Nu știu ce a hotărât gărgărița în final. Știu doar că zbuciumul ei a învățat-o că aripile îți dau libertate doar atunci când sunt larg deschise în zbor, altfel sunt doar o povară grea pe care o porți în spate. Nefericit.",
  ]?.map((item, key) => {
    return (
      <ParagraphItalicStyled key={key} animated={true}>
        {TextAlignment(item)}
      </ParagraphItalicStyled>
    );
  });
  const [logoutButton, setLogoutButton] = React.useState(false);
  React.useEffect(() => {
    const user = localStorage?.getItem("user");
    if (user && user !== "null") {
      setLogoutButton(true);
    }
  }, [localStorage?.getItem("user")]);
  return (
    <BackgroundWrapper imgUrl={Background} separatePage={props.separatePage}>
      {logoutButton && (
        <LogoutImage
          onClick={() => {
            localStorage?.removeItem("user");

            setLogoutButton(false);
            window.location.reload();
          }}
        >
          <p>Logout</p>
          <img src={logout} alt={"logout"} height={30} />
        </LogoutImage>
      )}
      <SectionContainer>
        <AdminButtons>
          <EditButton
            editFunction={contextLocal?.editFunction}
            sectionText={contentText}
            setSectionName={contextLocal.setSectionName}
            section={"weeksstory"}
          />
        </AdminButtons>
        <TitleSection color={colors.primary.base} animated={props.separatePage}>
          {contentText.title || "Povestea Săptămânii"}
        </TitleSection>
        <YoutubeEmbed embedId={contentText.video} animated={true} />
        {ParagraphAboutSection}
      </SectionContainer>
    </BackgroundWrapper>
  );
};

import React, { useEffect, useState } from "react";

import Axios from "axios";

import { StyledEditButton } from "./Styles";

interface EditButtonProps {
  editFunction: any;
  setSectionName: any;
  sectionText: any;
  object?: object;
  section: string;
}
export const EditButton = (props: EditButtonProps) => {
  const user = localStorage?.getItem("user");
  const [hasAccess, setAccess] = useState<boolean>(false);
  useEffect(() => {
    if (user !== undefined && user !== "null" && user !== null) {
      Axios.get(`/api/get-user-permissions`, {
        params: {
          token: user,
        },
      })
        .then((res) => {
          setAccess(true);
        })
        .catch((err) => {
          localStorage?.removeItem("user");
          window.location.reload();
        });
    }
  }, []);

  if (hasAccess && window.screen.width >= 1200) {
    return (
      <StyledEditButton
        onClick={() => {
          props.editFunction(props.sectionText, "edit", props.object);
          props.setSectionName(props.section);
        }}
      >
        EDIT "{props.sectionText.title}"
      </StyledEditButton>
    );
  }
  return <></>;
};

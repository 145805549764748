import React, { useState } from "react";

import styled from "styled-components";
import event1Image1 from "../../eventsImages/ce-mi-iau-din-iarna-care-trece.png"
import event1Image2 from "../../eventsImages/cum-scrii-povestea-ta-vindecatoare.png"
import event1Image3 from "../../eventsImages/masca-noastra-cea-de-toate-zilele.png"
import event1Image4 from "../../eventsImages/motive-aparente-ascunse-reale.png"
import { PDPContext } from "../../App";
import { colors } from "../../generalStyle";
import { getData } from "../../utils/getData";
import { CreateButton } from "../CreateButton";
import { EditButton } from "../EditButton";
import {
  AdminButtons,
  LogoutImage,
  SectionContainer,
  TitleSection,
  Tooltip,
} from "../Styles";
import logout from "../images/logout-icon.webp";
import { EventCard } from "./EventCard";

const StyledEventsPage = styled.div`
  margin-top: 80px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;
const StyledContent = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 30px;
  max-width: 850px;
  margin: 0 auto;
  > h1 {
    margin: 0;
  }
`;
const AnimatedContainer = styled.div`
  transform: translateX(70%);
  opacity: 0;
  animation: slide-in-anim-right 1.5s ease-out forwards;
  width: 100%;

  :nth-child(even) {
    transform: translateX(-70%);

    animation: slide-in-anim-left 1.5s ease-out forwards;
  }

  @keyframes slide-in-anim-right {
    20% {
      opacity: 0;
    }
    60% {
      transform: translateX(-10%);
    }
    75% {
      transform: translateX(5%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes slide-in-anim-left {
    20% {
      opacity: 0;
    }
    60% {
      transform: translateX(10%);
    }
    75% {
      transform: translateX(-5%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }
`;

export const events=[
  {
    titleSection: "Cum scrii povestea ta vindecătoare - joi la PoY",
    text:
        "A fost odată ca niciodată, că de n-ar fi, nu s-ar povesti! Câtă curiozitate și plăcere încă ne stârnesc cuvintele acestea, ce așteptare nerăbdătoare le urmează pe ele și ne farmecă pe noi?\n" +
        "\n" +
        "Poveștile ne caută prin suflete și răscolesc amintiri până găsesc cel mai vulnerabil colțișor al inimii și acolo râcâie, mângâie, și în final vindecă.\n" +
        "\n" +
        "Cum povestim în așa fel încât să vindecăm rănile ascunse acolo, în adânc? Ce cuvinte și imagini folosim, în ce contexte și de ce?Aflați joi, 21 aprilie, de la ora 18.30, alături de Doina Geru - L2 Practitioner PoY si NLP Master Practitioner, într-un workshop numit „Cum scrii povestea ta vindecătoare”.\n" +
        "\n" +
        "Inscrie-te la webinar aici >>> http://bit.ly/2yIRsFB",
    image: event1Image2,
    alt:"",
    date: "21 aprilie 2022",
    time:"18:30",
    website:"https://fb.me/e/29Us93kfr",
  },
  {
    titleSection: "Ce-mi iau din iarna care trece? - joi la PoY",
    text:"  Care este primul gând care îți apare când privești scena din cover? Ce îți place cel mai mult la ea? Ce nu îți place deloc? Unde ești tu în scenă? Ce emoție îți trezește și ce gust are emoția ta? A ce miroase?\n" +
        "De cine ți-e dor?\n" +
        "De când nu ați mai stat de vorbă?\n" +
        "De când nu ai mai stat de vorbă cu tine?\n" +
        "Și ce legătură au toate cele amintite cu viața ta de astăzi?\n" +
        "Vino să povestesti despre toate acestea joi, 27 ianuarie, de la 18.30, online, impreuna cu Doina Geru - Level 2 Practitioner Points of You® si NLP Master.\n" +
        "Inscrie-te la webinar aici >>> http://bit.ly/2yIRsFB" ,
    image: event1Image1,
    alt:"",
    date: "27 ianuarie 2022",
    time:"18:30",
    website:"http://bit.ly/2yIRsFB",
  },
  {
    titleSection: "MASCA NOASTRĂ CEA DE TOATE ZILELE",
    text:"Ați observat vreodată cum oamenii din jurul vostru reacționează disproporționat la evenimente obișnuite? Sau poate chiar voi vă comportați uneori în acest fel?\n" +
        "Aveți impresia că anumite situații dificile, considerate deja depășite, se repetă și nu vă explicați de ce vă regăsiți mereu în același tipar de comportament sau eveniment?\n" +
        "Despre posibile răspunsuri la aceste întrebări și la multe altele în evenimentul de joi, 18 noiembrie, începând cu ora 18:30!\n" +
        "Înscrie-te la webinar aici: http://bit.ly/2yIRsFB\n" +
        "Doar cei înscriși vor primi pe email linkul pentru a putea participa la curs.\n" +
        "Evenimentul este gratuit.",
    image: event1Image3,
    alt:"",
    date: "joi, 18 noiembrie",
    time:"18:30",
    website:"http://bit.ly/2yIRsFB",
  },
  {
    titleSection: "MOTIVE APARENTE, MOTIVE ASCUNSE, MOTIVE REALE",
    text:"Se spune că în spatele fiecărui comportament se află o intenție pozitivă. Chiar așa? Există intenții pozitive ale celor care mă supără, mă enervează, mă intrigă sau, în cel mai bun caz, pe care nu îi înțeleg?\n" +
        "Doina Geru, Level 2 Practitioner Points of You® si NLP Master Practitioner, vă invită la o întâlnire în care discutăm despre ce se ascunde dincolo de comportamente care deranjează, ce se află dincolo de aparențe, chiar dincolo de motivele invocate - în mod conștient sau subconștient - de cei care contează pentru noi.\n" +
        "Dacă vrei să participi, înscrie-te la webinar aici: http://bit.ly/2yIRsFB\n" +
        "Doar cei înscriși vor primi link pentru participare. Vă mulțumesc!",
    image: event1Image4,
    alt:"",
    date: "-",
    time:"-",
    website:"http://bit.ly/2yIRsFB",
  },
]
export const Events = () => {
  const contextLocal = React.useContext(PDPContext);
  const [dataSection, setDataSection] = useState({
    content: events,
    title: "Evenimente",
  });

  const eventCards = dataSection.content?.map((event, key) => {
    return (
      <AnimatedContainer key={key}>
        {localStorage?.user &&
            localStorage?.user !== "null" &&
          window.screen.width >= 1200 && (
            <button
              className={"delete-button"}
              onClick={() => {
                contextLocal?.editFunction(
                  { data: dataSection, index: key },
                  "delete"
                );
              }}
            >
              <Tooltip>Delete this event</Tooltip>X
            </button>
          )}
        <EventCard
          key={key}
          title={event.titleSection}
          text={event.text}
          eventImage={event.image ||''}
          date={event.date}
        />
      </AnimatedContainer>
    );
  });
  const [logoutButton, setLogoutButton] = React.useState(false);
  React.useEffect(() => {
    const user = localStorage?.getItem("user");
    if (user && user !== "null") {
      setLogoutButton(true);
    }
  }, [localStorage?.getItem("user")]);

  return (
    <StyledEventsPage>
      {logoutButton && (
        <LogoutImage
          onClick={() => {
            localStorage?.removeItem("user");
            setLogoutButton(false);
            window.location.reload();
          }}
        >
          <p>Logout</p>
          <img src={logout} alt={"logout"} height={30} />
        </LogoutImage>
      )}
      <SectionContainer>
        <AdminButtons>
          <CreateButton
            createFunction={contextLocal?.editFunction}
            sectionText={dataSection}
            createType="createEvent"
          />

          <EditButton
            editFunction={contextLocal?.editFunction}
            sectionText={dataSection}
            setSectionName={contextLocal.setSectionName}
            section={"events"}
          />
        </AdminButtons>
        <StyledContent>
          {
            <TitleSection color={colors.primary.base} animated={true}>
              {dataSection?.title}
            </TitleSection>
          }
          {eventCards}
        </StyledContent>
      </SectionContainer>
    </StyledEventsPage>
  );
};

import React, { useState } from "react";

import { PDPContext } from "../../App";
import { colors } from "../../generalStyle";
import { getData } from "../../utils/getData";
import { EditButton } from "../EditButton";
import {
  AdminButtons,
  BackgroundWrapper,
  MarginTOPContainer,
  ParagraphItalicStyled,
  SectionContainer,
  TitleSection,
} from "../Styles";
import { YoutubeEmbed } from "../YoutubeEmbed/YoutubeEmbed";
import Background from "../images/aboutBackground.webp";
import Axios from "axios";

export const AboutSection = () => {
  const contextLocal = React.useContext(PDPContext);

  // const [contentText, setContentTexts] = useState({
  //   title: "Despre Profa de povești",
  //   content: [
  //     "Eu nu am ales să fiu profesor. Când am terminat facultatea, am spus că voi merge să predau limba engleză o vreme, cât să îmi câștig existența până când voi găsi un post de translator într-o firmă celebră. Nu eram pregătită să trec de partea cealaltă a catedrei, acolo unde aflasem că e locul unor persoane severe și inflexibile, care îți cântăreau, cu privirea pe deasupra ochelarilor, orice comportament și îți scădeau nota la orice cuvânt folosit nefericit. Treptat, fiecare generație de elevi m-a convins că, de fapt, locul de la catedră este al celor care iubesc să învețe pe copii cum să își așeze pasiunea în muncă, libertatea în opțiuni și fericirea în viața de zi cu zi.",
  //   ],
  //   video: "C_BkCoJVzBU",
  // });
  const [contentText, setContentTexts] = useState({
    title: "",
    content: [
      "",
    ],
    video: "",
  });

  // React.useEffect(() => {
  //   const textData = getData(contextLocal?.allCategories, "AboutSection");
  //   setContentTexts(textData);
  // }, [contextLocal]);

  React.useEffect(() => {
    Axios.get("/api/get-content", {
      params: {
        section: "about",
      },
    }).then((res) => {
      if (res.data.content[0].data !== "{}") {
        const rawData = JSON.parse(res.data.content[0].data);
        setContentTexts(rawData);
      }
    });
  }, []);
  const ParagraphAboutSection = contentText.content?.map((item, key) => {
    return <ParagraphItalicStyled key={key}>{item}</ParagraphItalicStyled>;
  });

  return (
    <MarginTOPContainer>
      <BackgroundWrapper imgUrl={Background}>
        <SectionContainer>
          <AdminButtons>
            <EditButton
              editFunction={contextLocal?.editFunction}
              sectionText={contentText}
              setSectionName={contextLocal.setSectionName}
              section={"about"}
            />
          </AdminButtons>
          <TitleSection color={colors.primary.base}>
            {contentText.title}
          </TitleSection>
          {ParagraphAboutSection}
          <YoutubeEmbed embedId={contentText.video} />
        </SectionContainer>
      </BackgroundWrapper>
    </MarginTOPContainer>
  );
};
